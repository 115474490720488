<template>
  <div class="sysNotify">
    <div class="facilityBox">
      <div style="position: absolute;right:20px;top:125px;z-index: 999;">
        <el-tooltip placement="top">
          <div slot="content">
            1.添加一条通知，自动代入操作员信息，选择“发布时间”，写入“正文内容”与“备注”<br />2.点击“准备发布”按钮，关闭添加/编辑通知界面<br />3.该系统通知将在发布时间统一发送至平台所有用户<br />4.未发布状态的系统通知，可以编辑修改、可以删除。已发布则不可改。
          </div>
          <div style="margin: 0px 20px;color: #0F5FFF;">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>
       <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="未发布" name="first"></el-tab-pane>
        <el-tab-pane label="已发布" name="second"></el-tab-pane>
      </el-tabs>
      <div class="topbtns" style="margin-bottom: 20px" v-show="activeName == 'first'">
        <el-button v-show="activeName == 'first'" type="success" size="medium" @click="operateNotify('add')" icon="el-icon-circle-plus-outline"
          >添加</el-button
        >
        <el-button
          type="danger"
          v-show="activeName == 'first'"
          size="medium"
          @click="deleteNotify" icon="el-icon-remove-outline"
          >删除</el-button
        >
        <!-- <span
          :style="
            item.name == activeName
              ? 'cursor: pointer; border: 1px solid #2081FF; margin-right: 20px; color: #fff; background: #2081FF; padding: 5px 20px;box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);border-radius: 6px;'
              : 'cursor: pointer;margin-right: 20px; border: 1px solid #2081FF; color: #2081FF;  padding: 5px 20px;border-radius: 6px;'
          "
          @click="handleClick(item.name)"
          v-for="item in tabItem"
          :key="item.title"
          >{{ item.title }}</span
        > -->
      </div>
      <!-- 表单 -->
      <el-table
        :data="tableData"
        row-key="ID"
        @selection-change="tableSelectionChange"
        :header-cell-style="{color: '#666', background: '#f0f0f0'}"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="UserName"
          label="员工姓名"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="UserPhone"
          width=120
          label="员工手机号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="UserIdCard"
          width=200
          label="员工身份证"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="CreateDate"
          label="创建时间"
          width=180
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="PublishDate"
          label="发布时间"
          width=180
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="MsgTypeName"
          label="类型"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="MsgContent"
          label="正文内容"
          width=400
          show-overflow-tooltip
        >
          
          <template slot-scope="scope">
            <el-popover
            placement="bottom-start"
            width="400"
            trigger="hover"
            >
            <div v-html="scope.row.MsgContent"></div>
            <div slot="reference">{{ scope.row.msg }}</div>
          </el-popover>
          
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Status" label="发布状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.PublishStatus }}</el-tag>
            <!-- <div style="color: #00e855">{{ scope.row.PublishStatus }}</div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="备注" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff2323">{{ scope.row.Remark }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="122px">
          <template slot-scope="scope">
            <el-button size="small"
             type="primary" 
             v-show="activeName == 'first'"
             icon="el-icon-edit-outline"
             @click="operateNotify('edit', scope.row)">编辑</el-button>
            <!-- <div
              :class="activeName == 'second' ? 'rolebtnDisable' : 'roleBtn'"
              style="padding: 4px 14px"
              @click="operateNotify('edit', scope.row)"
            >
              <img :src="ossHost + 'editIcon.png'" />
              <span>编辑</span>
            </div> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background style="margin-top:10px"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.pageIndex"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
    <!--添加编辑消息-->
    <el-dialog
      :visible.sync="notifyDialog"
      width="540px" class="deep_dialog"
    >
      <span slot="title">{{type == 'add' ? '添加通知' : '编辑通知'}}</span>
      <el-form
        :model="notifyInfo"
        label-width="110px"
        ref="notifyInfo"
        :rules="addrules"
      >
        <el-form-item label="员工姓名" prop="name">
          <el-input style="width: 332px"
            v-model="getName"
            auto-complete="off"
            maxlength="20"
            disabled
            clearable
            placeholder="请输入员工姓名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input style="width: 332px"
            v-model="getPhone"
            auto-complete="off"
            maxlength="20"
            disabled
            clearable
            placeholder="请输入手机号码"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="IdCardNum">
          <el-input style="width: 332px"
            v-model="getIdCard"
            auto-complete="off"
            maxlength="20"
            disabled
            clearable
            placeholder="请输入身份证号"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="创建时间" prop="createTime">
          <el-date-picker style="width: 332px"
            v-model="notifyInfo.createTime"
            type="date"
            disabled
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发布日期" prop="publishTime">
          <el-date-picker style="width: 332px"
            v-model="notifyInfo.publishTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知类型" prop="type">
          <el-select style="width: 332px"
            disabled
            v-model="notifyInfo.type"
            placeholder="请选择消息类型"
          >
            <el-option
              v-for="item in msgTypeList"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input style="width: 332px"
            v-model="notifyInfo.remark"
            auto-complete="off"
            maxlength="50"
            clearable
            placeholder="请输入备注信息"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="正文内容" prop="content">
          <el-input
            style="width: 332px"
            v-model="notifyInfo.content"
            auto-complete="off"
            clearable
            placeholder="请输入正文内容"
            type="textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="publishNotify('notifyInfo')"
          >准备发布</el-button
        >
        <el-button @click="notifyDialog = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import { mapGetters, mapMutations } from "vuex";
import {
  getNotifyList,
  addNotify,
  editNotify,
  deleteNotify,
  getMsgType,
} from "@/api/notify/notify.js";
export default {
  mixins: [basicMixin],
  data() {
    return {
      pagination: {
        //分页控件相关参数
        pageIndex: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // Tab切换
      activeName: "first",
      type: "add", // 添加还是编辑消息
      notifyDialog: false, // 消息弹框
      msgTypeList: [], // 消息类型列表
      notifyID: "", // 需要编辑的消息id
      notifyInfo: {
        createTime: Date.now(), // 创建时间
        type: 1, // 消息类型
        publishTime: "", // 发布时间
        remark: "", // 备注
        content: "", // 正文内容
      }, // 消息信息
      // tabItem: [
      //   {
      //     name: "first",
      //     title: "未发布",
      //   },
      //   {
      //     name: "second",
      //     title: "已发布",
      //   },
      // ],
      tableData: [], // 表格数据
      selectedTableItemList: [], // 选中要删除的消息ID列表
      // 添加表单验证
      addrules: {
        publishTime: [
          {
            required: true,
            message: "请选择发布时间",
            trigger: "change",
          },
        ],
        content: [
          {
            required: true,
            message: "请填写正文内容",
            max: 500,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getName", "getPhone", "getIdCard"]),
  },
  methods: {
    // Tab切换
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.pagination.pageIndex = 1;
      this.getNotifyList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.pageIndex = e;
      this.getNotifyList();
    },
    // 获取通知列表
    getNotifyList() {
      let publishStatus = "";
      if (this.activeName == "first") {
        publishStatus = false;
      } else {
        publishStatus = true;
      }
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.pageIndex,
        publishStatus,
      };
      getNotifyList(params).then((res) => {
        res.data.DataList.forEach(element => {
          if(element.MsgContent.length > 50) {
            element.msg = element.MsgContent.substring(0,40) + '...'
            element.MsgContent = element.MsgContent.replace(/\n/g, '<br>')
          } else {
             element.msg = element.MsgContent
             element.MsgContent = element.MsgContent.replace(/\n/g, '<br>')
          }
        });
        this.tableData = res.data.DataList;
        this.pagination.total = Number(res.data.TotalCount);
      });
    },
    // 删除消息
    deleteNotify() {
      if (this.selectedTableItemList.length === 0) {
        this.$message.error("先“单击”一个角色，再点击“删除”按钮，即可删除。");
        return;
      }
      let arr = [];
      this.selectedTableItemList.forEach((element) => {
        arr.push(element.ID);
      });
      let params = {
        msgIds: arr.join(",")
      }
      this.$confirm("删除此通知将无法恢复，是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNotify(params).then((res) => {
          this.pagination.pageIndex = 1;
          this.selectedTableItemList = []
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getNotifyList();
        });
      });
    },
    // 添加编辑消息
    operateNotify(scope, row) {
      if (scope == "add") {
        this.type = "add";
        this.notifyDialog = true;
        if(this.$refs["notifyInfo"]) {
          this.$refs["notifyInfo"].resetFields();
        }
        this.notifyID = "";
        this.notifyInfo.publishTime = "";
        this.notifyInfo.remark = "";
        this.notifyInfo.content = "";
      }
      if (scope == "edit") {
        if (this.activeName == "second") return;
        this.type = "edit";
        this.notifyDialog = true;
        this.notifyID = row.ID;
        this.notifyInfo.publishTime = row.PublishDate;
        this.notifyInfo.remark = row.Remark;
        this.notifyInfo.content = row.MsgContent.replace(/<br>/g, '\n');
      }
    },
    // 发布通知
    publishNotify() {
      if (this.notifyInfo.publishTime == "") {
        this.$message({
          message: "请选择发布时间",
          type: "warning",
        });
        return;
      }
      if (this.notifyInfo.content == "") {
        this.$message({
          message: "请输入正文内容",
          type: "warning",
        });
        return;
      }
      let params = {
        id: this.notifyID,
        msgTypeID: 1,
        remark: this.notifyInfo.remark,
        publishDate: this.notifyInfo.publishTime,
        msgContent: this.notifyInfo.content,
        isVital: true,
        isComple: true,
        users: [0],
      };
      if (!this.notifyID) {
        delete params.id;
        addNotify(params).then((res) => {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.getNotifyList();
          this.notifyDialog = false;
        });
      } else {
        editNotify(params).then((res) => {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.getNotifyList();
          this.notifyDialog = false;
        });
      }
    },
    // 获取消息通知类型
    getMsgType() {
      getMsgType().then((res) => {
        this.msgTypeList = res.data;
      });
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e;
    },
  },

  mounted() {},
  created() {
    this.getNotifyList();
    this.getMsgType();
  },
};
</script>
<style lang="scss">
.el-popover {
    max-height: 400px;
    overflow-y: auto !important;
  }
.sysNotify {
  
  .role {
    .el-dialog__body {
      display: flex;
      justify-content: center;
    }
  }

  .infos {
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 16px;
    width: 320px;
    display: flex;
    justify-content: center;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__label {
      text-align: left;
      color: #333333;
    }
    .el-input__inner {
      border: none;
    }
    .el-form-item__error {
      position: absolute;
      left: 14px !important;
    }
    .el-input.is-disabled .el-input__inner {
      background-color: #fff;
    }
  }
  .info {
    margin-bottom: 16px;
    width: 320px;
    font-size: 14px;
    padding-left: 6px;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-textarea__inner {
      height: 80px;
    }
    .name {
      margin-bottom: 8px;
      color: #333333;
      //  margin-left: 10px;
    }
    .el-form-item__error {
      position: absolute;
      left: 14px !important;
    }
    .el-input__inner {
      border: none;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";
.sysNotify {
  .topbtns{
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }
  .tips {
    margin-bottom: 4px;
  }
  .tips:last-child {
    margin-bottom: 16px;
  }
  .rolebtnDisable {
    background: #a0cfff;
    display: flex;
    align-items: center;
    display: inline-block;
    color: #fff;
    padding: 4px 8px;
    border-radius: 6px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
    img {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .roleBtn {
    background: $primary;
    display: flex;
    align-items: center;
    display: inline-block;
    color: #fff;
    padding: 4px 8px;
    border-radius: 6px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
    img {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .btns {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }
  .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-item {
      width: 50%;
    }
    .el-date-editor {
      width: 100% !important;
    }
  }
  .pagination {
    margin-top: 10px;
  }
  .addDialog {
    .body {
      .el-autocomplete {
        width: 100%;
      }
      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.deep_dialog{
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer{
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>