import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 添加通知
export const addNotify = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Msg/Add`,params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 修改通知
export const editNotify = (params) => {
    return new Promise((resolve, reject) => {
        axios.put(`${host}/api/Msg/Update`,params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 删除通知
export const deleteNotify = (params) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${host}/api/Msg/Delete?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
//查询系统通知列表
export const getNotifyList = (params) => {
    return new Promise((resolve, reject) => {  
        axios.post(`${host}/api/Msg/List`,params,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 消息添加的类型 
export const getMsgType = () => {
    return new Promise((resolve, reject) => {  
        axios.get(`${host}/api/Msg/MsgTypeList`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}